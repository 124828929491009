import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";

@Component({
  components: {
    DmHeader: () => import(/* webpackChunkName: 'dm-header' */ "@/vue/components/dm-header/dm-header.vue"),
    DmPdfViewer: () => import(/* webpackChunkName: 'dm-pdf-viewer' */ "@/vue/components/dm-pdf-viewer/dm-pdf-viewer.vue"),
  }
})
export default class DfPageHomeComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;
}
